<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form#FormProjectCreate(endpoint='/_/Projects/*' @submitSuccess='onSubmitSuccess')
    template(#default='{ loading }')
      form-header
        span(v-html='$t("Add Project")')
        template(#below)
          p(class='text-base text-text-light' v-html='$t("Projects allow you to manage product lists and create offers.")')
      form-input(name='Name' :placeholder='$t("i.e. My New Project")' :note='$t("i.e. My New Project")' :autofocus='true')
      form-input-money(name='Budget' :markOptional='true') €
      form-input-date(name='Deadline' :markOptional='true')
      form-textarea(name='Description' :markOptional='true' :label='$t("Description", "Projects")')
      form-footer
        form-action(name='Create' :primary='true' :loading='loading' class='font-bold')
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData } from '../types'
import { type IFormResponseInfo } from '@/components/GenericForm/types'

const emit = defineEmits(['close'])
const store = useProjectsStore()
const onSubmitSuccess = (e: IFormResponseInfo<IProjectData>) => {
  store.setProject(e.data)
  if (e.action === 'Create') {
    emit('close')
  }
}
</script>
